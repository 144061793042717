import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = (props) => (
  <Layout links={props.data.menu.edges}>
    <div id="main" className="alt">
        <section id="one">
            <div className="inner">
                <h1>NOT FOUND</h1>
                <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
            </div>
        </section>
    </div>
  </Layout>
)

export const errorPage = graphql`
  query {
    menu: allContentfulProject {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
  }
`

export default NotFoundPage
